export enum UploadItemType {
  APPLICANT_FRONT_OF_LICENSE = 'APPLICANT_FRONT_OF_LICENSE',
  APPLICANT_BACK_OF_LICENSE = 'APPLICANT_BACK_OF_LICENSE',
  CO_APPLICANT_FRONT_OF_LICENSE = 'CO_APPLICANT_FRONT_OF_LICENSE',
  CO_APPLICANT_BACK_OF_LICENSE = 'CO_APPLICANT_BACK_OF_LICENSE',
  PROOF_OF_INSURANCE = 'PROOF_OF_INSURANCE',
  REGISTRATION_CARD = 'REGISTRATION_CARD',
  PHOTO_OF_ODOMETER = 'PHOTO_OF_ODOMETER',
  ADDITIONAL_DOCUMENT = 'ADDITIONAL_DOCUMENT',
}
