import { merge } from 'lodash';

import { fields } from '../../../../../forms/application';
import { populateFields } from '../../../../../forms/populateFields';
import { Signal } from '../../../../common/constants/signals';
import { AccountState } from '../../common/unified-workflow-context.interface';
import BaseModel from '../common/BaseModel';
import { defaultOptions } from './utils';

const model = (send: any, { context: { user, coApplicant } }: AccountState) => {
  const options = defaultOptions;
  const formFields = {
    primaryGoal: {
      ...fields.primaryGoal,
      options: options,
      defaultValue: options[0].value,
    },
  };
  const savedFields = populateFields(formFields, user);
  const populatedFields = merge({}, formFields, savedFields);
  const progress = coApplicant?.coApplicantAnswer ? 27 : 33;

  return merge({}, BaseModel, {
    template: {
      header: {
        showBack: true,
        onBack: () => {
          send(Signal.Previous);
        },
      },
    },
    headerBlock: {
      progressBar: {
        progress,
        withContainer: true,
      },
      title: 'I am primarily interested in:',
      subtitle: ['Account', `${progress}% complete`],
    },
    form: {
      fields: populatedFields,
      actions: {
        primary: {
          testId: 'continue',
          label: 'Continue',
          handler: (data: any) => {
            send(Signal.Next, { data: { user: merge({}, user, data) } });
          },
        },
      },
    },
    info: {
      title: 'Primary goal',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'There are many reasons one may be inclined to refinance your auto loan. By providing a goal that most interests you, we can provide the most relevant offers that satisfy said goal.',
          ],
        },
      ],
    },
  });
};

export default model;
