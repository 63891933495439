import { devtools } from 'zustand/middleware';

import useApiStore from '../apiStore';
import useDashboardStore from '../dashboardStore';
import { create, storeDevToolOptions } from '../storeManager';
import useUserStore from '../userStore';
import { getVerifications } from './functions/getVerifications';
import { VerificationState } from './types/VerificationState';
import { verificationStore } from './verificationStore';

const useVerificationStore = create({ hmrPersist: 'verificationStore' })(
  devtools<VerificationState>(
    verificationStore({
      getVerifications,
      useApiStore,
      useDashboardStore,
      useUserStore,
    }),
    storeDevToolOptions('verificationStore'),
  ),
);

export default useVerificationStore;
