import axios, { AxiosError, AxiosInstance } from 'axios';
import { get as lodashGet } from 'lodash';
import { devtools } from 'zustand/middleware';

import { HttpStatusCode } from '../common';
import { config } from '../config';
import useParameterStore from './parameterStore';
import useSignOutStore from './signOutStore';
import { create, storeDevToolOptions } from './storeManager';

type ApiState = {
  isLoading: boolean;
  isLoadingTransition: boolean;
  handleUnauthorized: (error: AxiosError) => any;
  httpRequest: () => AxiosInstance;
  radixApi: () => AxiosInstance;
};

const loadingTransitionTime = 1200; // ms

const useApiStore = create()(
  devtools<ApiState>(
    (set, get) => ({
      isLoading: false,
      // isLoadingTransition is used to prevent flickering of loading state
      // when a request is resolved too quickly
      isLoadingTransition: false,

      handleUnauthorized: (error: AxiosError) => {
        const statusCode = lodashGet(error, 'response.status');

        const { token } = useParameterStore.getState();
        const { isSigningOut, signOut } = useSignOutStore.getState();

        if (
          statusCode === HttpStatusCode.UNAUTHORIZED &&
          token &&
          !isSigningOut // ensure the signout process is not already in progress
        ) {
          signOut({
            withAuthRedirectPath: window.location.pathname,
          });
        }
      },
      httpRequest: () => axios.create(),
      radixApi: () => {
        const radixHttpRequest = axios.create({
          baseURL: new URL(config.api.path, config.api.baseUrl).href,
          headers: {},
          withCredentials: true,
        });

        radixHttpRequest.interceptors.request.use((axiosConfig) => {
          set({ isLoading: true, isLoadingTransition: true });

          const { token } = useParameterStore.getState();

          if (token && axiosConfig?.headers) {
            axiosConfig.headers['X-Access-Token'] = token;
          }
          return axiosConfig;
        });

        radixHttpRequest.interceptors.response.use(
          (response) => {
            set({ isLoading: false });

            setTimeout(
              () => set({ isLoadingTransition: false }),
              loadingTransitionTime,
            );

            const {
              data: { token, ...data },
            } = response;

            if (token) {
              useParameterStore.setState({ token });
            }

            return response.data || data;
          },
          (error) => {
            set({ isLoading: false });

            setTimeout(
              () => set({ isLoadingTransition: false }),
              loadingTransitionTime,
            );

            get().handleUnauthorized(error);

            return Promise.reject(error);
          },
        );

        return radixHttpRequest;
      },
    }),
    storeDevToolOptions('apiStore'),
  ),
);

export default useApiStore;
