import { orderBy } from 'lodash';

import { MappedRefinanceOffer } from '../../../../../common/interfaces/vehicle/offer.interface';
import { InterestChoices } from '../../../../../constants/interestChoices';
import { isPurchaseApplication } from '../../../../../utils/applicationType';
import { OffersContext } from '../../../../common/interfaces/offers.interface';

export const sortOffers = (offers: any[], selectedInterest: any) => {
  const params =
    selectedInterest === InterestChoices.LOWEST_INTEREST_RATE
      ? ['offer.pctAPR', 'offer.amtPayment']
      : ['offer.amtPayment', 'offer.pctAPR'];

  return orderBy(offers, params).map((offer, index) => {
    if (index === 0) {
      offer.highlight = 'Recommended';
    } else {
      offer.highlight = null;
    }
    return offer;
  });
};

export const formatOptions = (
  offersWithoutGap: MappedRefinanceOffer[],
  paymentAmount: number,
  interest: any,
) => {
  const options = offersWithoutGap?.map((offer) => {
    const calculatedAPR = parseFloat((offer.pctAPR * 100).toFixed(2));

    return {
      label: `$${offer.amtPayment.toFixed()}/month     |     ${calculatedAPR}% apr`,
      subtitle: `${offer.term} month term`,
      badge:
        offer.monthlySavingsAmount > 0
          ? `Save $${offer.monthlySavingsAmount.toFixed()}/mo`
          : null,
      offer,
      highlight: null,
      showFooterOnMobile: true,
    };
  });

  const sorted = sortOffers(options, interest);
  return sorted.map((o, index) => ({ ...o, value: index.toString() }));
};

export const defaultInterest = InterestChoices.LOWEST_PAYMENT;

export const getMaxDownPayment = (context: OffersContext) => {
  const MIN_LOAN_VALUE = 5000;
  const comparableValue = isPurchaseApplication(context.currentVehicle)
    ? context.currentVehicle.purchasePrice
    : context.currentVehicle.loan?.payoffAmount;

  if (!comparableValue) {
    return 0;
  }
  return Math.max(
    (Number.isInteger(comparableValue) ? comparableValue : 0) - MIN_LOAN_VALUE,
    0,
  );
};
