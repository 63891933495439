import i18n from 'i18next';

import { UploadItemType } from '../enums/UploadItemType';

export const uploadItemTypeName: Record<UploadItemType, string> = {
  [UploadItemType.APPLICANT_FRONT_OF_LICENSE]: i18n.t('files.frontOfLicense'),
  [UploadItemType.APPLICANT_BACK_OF_LICENSE]: i18n.t('files.backOfLicense'),
  [UploadItemType.CO_APPLICANT_FRONT_OF_LICENSE]: i18n.t(
    'files.frontOfLicense',
  ),
  [UploadItemType.CO_APPLICANT_BACK_OF_LICENSE]: i18n.t('files.backOfLicense'),
  [UploadItemType.PROOF_OF_INSURANCE]: i18n.t('files.proofOfInsurance'),
  [UploadItemType.REGISTRATION_CARD]: i18n.t('files.registrationCard'),
  [UploadItemType.PHOTO_OF_ODOMETER]: i18n.t('files.photoOfOdometer'),
  [UploadItemType.ADDITIONAL_DOCUMENT]: i18n.t('files.additionalDocument'),
};
