export const optionsList = [
  {
    value: true,
    label: 'Add co-applicant',
    testId: 'true',
  },
  {
    value: false,
    label: 'No co-applicant',
    testId: 'false',
  },
];
