import i18n from 'i18next';

import { ALLOWED_FILE_TYPES } from '../constants/validation';

export const validateFileFormat = (file: File): string | null => {
  if (!ALLOWED_FILE_TYPES.includes(file.type)) {
    return i18n.t('validation.file.formatInvalid', {
      fileName: file.name,
    });
  }

  return null;
};
