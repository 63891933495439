import { get, uniqueId } from 'lodash';

import useDashboardStore from '../../dashboardStore';
import { getFilePreview } from '../../loanDocumentStore/functions/getFilePreview';
import useUserStore from '../../userStore';
import { uploadItemTypeMetadata } from '../constants/uploadItemTypeMetadata';
import { uploadItemTypeName } from '../constants/uploadItemTypeName';
import { UploadItemType } from '../enums/UploadItemType';
import { isFilePDF } from '../functions/isFilePDF';

export class UploadItem {
  file?: File;
  type: UploadItemType;

  constructor(type: UploadItemType) {
    this.type = type;
  }

  get fileName() {
    return get(this.file, 'name');
  }

  get fileType() {
    return get(this.file, 'type');
  }

  get hasFile() {
    return Boolean(this.file);
  }

  get hasPDF() {
    if (!this.hasFile) {
      return false;
    }

    return isFilePDF(this.file);
  }

  get metadata() {
    const metadata = uploadItemTypeMetadata[this.type];

    metadata.autopayNumber = this.sourceId;
    metadata.contentType = this.fileType;
    metadata.documentName = this.uniqueFileName;
    metadata.uniqueIdentifier = this.uniqueIdentifier;

    return metadata;
  }

  get name() {
    return uploadItemTypeName[this.type];
  }

  get previewURL() {
    if (!this.hasFile) {
      return null;
    }

    return getFilePreview(this.file);
  }

  get sourceId() {
    return useDashboardStore.getState().computed.vehicleApplicationSourceId;
  }

  get uniqueFileName() {
    // supports multiple files with the same name
    return uniqueId(`${this.fileName}-`);
  }

  get uniqueIdentifier() {
    return useUserStore.getState().computed.userLastName;
  }

  setFile(file: File) {
    this.file = file;
  }
}
