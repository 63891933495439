import { UnifiedWorkflowDynamicConfiguration } from '../../assembly/UnifiedDefaultSignUp/common/unified-workflow-context.interface';

export const unifiedWorkflowDefaultConfiguration: UnifiedWorkflowDynamicConfiguration =
  {
    primaryGoalsList: [
      {
        label: 'Lower monthly payment',
        value: 'LOWER_PAYMENT',
      },
      {
        label: 'Paydown other Debts',
        value: 'PAYDOWN_OTHER_DEBTS',
      },
      {
        label: 'Take someone off the loan',
        value: 'REMOVE_SOMEONE',
      },
      {
        label: 'Just checking my options',
        value: 'CHECKING_OPTIONS',
      },
    ],
    showEstimatedPayoffAmount: true,
  };
