export const VehicleInformationSource = {
  Vin: 'Vin',
  MakeAndModel: 'MakeAndModel',
  LicensePlate: 'LicensePlate',
};

export const optionsList = (includeLicensePlateSearch?: boolean) => [
  {
    value: VehicleInformationSource.MakeAndModel,
    label: 'Make & Model',
    testId: VehicleInformationSource.MakeAndModel,
  },
  ...(includeLicensePlateSearch
    ? [
        {
          value: VehicleInformationSource.LicensePlate,
          label: 'License Plate',
          testId: VehicleInformationSource.LicensePlate,
        },
      ]
    : []),
  {
    value: VehicleInformationSource.Vin,
    label: 'VIN Lookup',
    testId: VehicleInformationSource.Vin,
  },
];
