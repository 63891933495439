import { useContext } from 'react';

import { Branding } from '../../common/interfaces/branding/branding';
import { defaultChecklightBranding } from '../../providers/context/DynamicBrandingProvider/checklight-branding';
import { DynamicBrandingContext } from '../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { setDynamicAppInfo } from '../../utils/setDynamicAppInfo/setDynamicAppInfo';

export const useBranding = () => {
  const { branding, changeBrandingState, failedToRetrieveBranding, isLoading } =
    useContext(DynamicBrandingContext);
  const initializeBranding = async (
    api: any,
    leadChannelCode?: string | undefined,
  ) => {
    let brandingState: Branding = branding;
    try {
      let fetchedBranding;

      if (leadChannelCode) {
        try {
          fetchedBranding = await api.get(
            `/ui-configuration/branding?leadChannelCode=AUTOPAY_STELLANTIS_FAST_TRACK_API`,
          );
        } catch (error) {
          fetchedBranding = defaultChecklightBranding;
        }
      }

      brandingState = {
        ...(fetchedBranding || defaultChecklightBranding),
        id: fetchedBranding.id,
        brandName: fetchedBranding.brandName,
      };

      changeBrandingState({
        branding: brandingState,
        isLoading: false,
      });
    } catch (e: any) {
      console.error('Failed to load branding, using default', e);
    } finally {
      changeBrandingState({ isLoading: false });
      setDynamicAppInfo(brandingState);
    }
  };

  return {
    initializeBranding,
    branding,
    failedToRetrieveBranding,
    isLoading,
  };
};
