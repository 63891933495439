import settingsListIcon from '../../../../../assets/settings-list.png';
import { ImageFitContainer } from '../../../../../components/shared/ImageFitContainer/ImageFitContainer';

export const defaultOptions = [
  {
    label: 'Lower monthly payment',
    value: 'LOWER_PAYMENT',
  },
  {
    label: 'Paydown other Debts',
    value: 'PAYDOWN_OTHER_DEBTS',
  },
  {
    label: 'Take someone off the loan',
    value: 'REMOVE_SOMEONE',
  },
  {
    label: 'Just checking my options',
    value: 'CHECKING_OPTIONS',
  },
];

export const addImagesToOptions = (options: any[] = []) =>
  options.map(({ image, ...option }) => ({
    ...option,
    image: <ImageFitContainer src={image || settingsListIcon} />,
  }));
