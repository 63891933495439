import i18n from 'i18next';

import { MAX_FILE_SIZE } from '../constants/validation';

export const validateFileSize = (file: File): string | null => {
  if (file.size > MAX_FILE_SIZE) {
    return i18n.t('validation.file.sizeExceeded', {
      fileName: file.name,
      maxFileSize: `${MAX_FILE_SIZE / 1e6}MB`,
    });
  }

  return null;
};
