import i18n from 'i18next';

import { UploadItemType } from '../enums/UploadItemType';
import { VerificationStatus } from '../enums/VerificationStatus';
import { UploadItem } from '../types/UploadItem';
import { Verification } from '../types/Verification';

export const getVerifications = ({
  vehicleApplicationHasCoapplicant,
  vehicleApplicationIsLeaseBuyoutType,
  vehicleApplicationIsRefinanceType,
  vehicleId,
}: {
  vehicleApplicationHasCoapplicant: boolean;
  vehicleApplicationIsLeaseBuyoutType: boolean;
  vehicleApplicationIsRefinanceType: boolean;
  vehicleId: string;
}): Verification[] =>
  [
    {
      copy: i18n.t('verifications.applicantDriversLicense.copy'),
      headline: i18n.t('verifications.applicantDriversLicense.headline'),
      name: i18n.t('verifications.applicantDriversLicense.name'),
      path: `/verifications/${vehicleId}/upload-license`,
      status: VerificationStatus.REQUESTED,
      uploadItems: [
        new UploadItem(UploadItemType.APPLICANT_FRONT_OF_LICENSE),
        new UploadItem(UploadItemType.APPLICANT_BACK_OF_LICENSE),
      ],
    },
    {
      condition: vehicleApplicationHasCoapplicant,
      copy: i18n.t('verifications.coapplicantDriversLicense.copy'),
      headline: i18n.t('verifications.coapplicantDriversLicense.headline'),
      name: i18n.t('verifications.coapplicantDriversLicense.name'),
      path: `/verifications/${vehicleId}/coapp-upload-license`,
      status: VerificationStatus.REQUESTED,
      uploadItems: [
        new UploadItem(UploadItemType.CO_APPLICANT_FRONT_OF_LICENSE),
        new UploadItem(UploadItemType.CO_APPLICANT_BACK_OF_LICENSE),
      ],
    },
    {
      copy: i18n.t('verifications.proofOfInsurance.copy'),
      headline: i18n.t('verifications.proofOfInsurance.headline'),
      name: i18n.t('verifications.proofOfInsurance.name'),
      path: `/verifications/${vehicleId}/upload-insurance`,
      status: VerificationStatus.REQUESTED,
      uploadItems: [new UploadItem(UploadItemType.PROOF_OF_INSURANCE)],
    },
    {
      condition:
        vehicleApplicationIsRefinanceType ||
        vehicleApplicationIsLeaseBuyoutType,
      copy: i18n.t('verifications.registrationCard.copy'),
      headline: i18n.t('verifications.registrationCard.headline'),
      name: i18n.t('verifications.registrationCard.name'),
      path: `/verifications/${vehicleId}/upload-registration`,
      status: VerificationStatus.REQUESTED,
      uploadItems: [new UploadItem(UploadItemType.REGISTRATION_CARD)],
    },
    {
      condition:
        vehicleApplicationIsRefinanceType ||
        vehicleApplicationIsLeaseBuyoutType,
      copy: i18n.t('verifications.photoOfOdometer.copy'),
      headline: i18n.t('verifications.photoOfOdometer.headline'),
      name: i18n.t('verifications.photoOfOdometer.name'),
      path: `/verifications/${vehicleId}/upload-odometer`,
      status: VerificationStatus.REQUESTED,
      uploadItems: [new UploadItem(UploadItemType.PHOTO_OF_ODOMETER)],
    },
    {
      copy: i18n.t('verifications.additionalDocuments.copy'),
      headline: i18n.t('verifications.additionalDocuments.headline'),
      name: i18n.t('verifications.additionalDocuments.name'),
      path: `/verifications/${vehicleId}/upload-additional`,
      status: VerificationStatus.OPTIONAL,
      uploadItems: [new UploadItem(UploadItemType.ADDITIONAL_DOCUMENT)],
    },
  ].filter((verification) =>
    'condition' in verification ? verification.condition : true,
  );
